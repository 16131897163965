body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #576772;
  color: white;
}

header {
  background-color: white;
  color: black;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  margin-left: 80px;
  height: 130px;
  animation: float-in 1s ease-out forwards;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 80px;
}

nav ul li {
  margin-right: 20px;
  animation: float-in 1s ease-out forwards;
}

nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  animation: float-in 1s ease-out forwards;
}

nav ul li a.active {
  color: crimson; /* Crimson color for the active link */
}

nav ul li a:hover {
  text-decoration: underline;
}

main {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 60px);
  text-align: center;
}

.home-logo {
  margin-top: -100%;
  height: 500px;
  animation: float-in 1s ease-out forwards;
}

.home-subtitle {
  font-size: 1.5em;
  margin-top: -12%;
  animation: float-in 1s ease-out forwards;
}

h1 {
  font-size: 2em;
}

p {
  font-size: 1.2em;
}

.contact {
  margin-top: -32%;
  animation: float-in 1s ease-out forwards;
}
/* Float-in Animation */
@keyframes float-in {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }
  .logo img {
    margin-left: 0px;
  }
  nav ul {
    justify-content: right;
  }

  nav ul li {
    margin: 10px 10px;
  }

  main {
    padding: 10px;
  }

  .home-logo {
    height: 450px;
    animation: float-in 1s ease-out forwards;
  }

  .home-subtitle {
    margin-bottom: 80%;
    margin-top: 0px;
    font-size: 1.2em;
    animation: float-in 1s ease-out forwards;
  }

  .contact {
    margin-bottom: 80%;
  }
}

@media (max-width: 480px) {
  .logo img {
    height: 80px;
  }

  .home-logo {
    height: 200px;
  }

  .home-subtitle {
    font-size: 1em;
  }
}
